import { Button, IconButton, Stack, TextField } from '@mui/material'
import React, { useState } from 'react'
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { dataCheng, onInputCheng, openModel, playerClear } from '../Stores/Slice/modelSlice';
import axios, { apiRoutes } from '../Constants';
import { Delete, Edit } from '@mui/icons-material';
import { fetcchplayer } from '../Stores/Slice/playerSlice'
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';
const playerSkill = [
  "ALL ROUNDER",
  "BATSMAN",
  "BOWLER",
  "W.K. / BATSMAN",
]

function Player() {
  const { player } = useSelector(state => state.palyer)
  const dispatch = useDispatch()
  const deletes = (id) => {
    axios.delete(`${apiRoutes.player}${id}`).then((e) => {
      dispatch(fetcchplayer())
      toast.success(e.data.msg)
      // dispatch(openModel({ st: false, key: 'auctionModel' }))
    })
  }
  console.log(player);
  return (
    <>
      <Stack display={'flex'} direction={'row'} justifyContent={'flex-end'} my={'20px'} >
        <Button variant='contained' onClick={() => dispatch(openModel({ st: true, key: 'playerModel' }))} >
          Create New Player
        </Button>
      </Stack>
      <CateguryModel />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>#</StyledTableCell>
              <StyledTableCell align="center">Player</StyledTableCell>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Mobile</StyledTableCell>
              <StyledTableCell align="center">Skill</StyledTableCell>
              <StyledTableCell align="center">Base Price</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {player.map((row, key) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {++key}
                </StyledTableCell>
                <StyledTableCell align="center" component="th" scope="row">
                  {row?.player_no}
                </StyledTableCell>
                <StyledTableCell align="center" component="th" scope="row">
                  {row?.name}
                </StyledTableCell>
                <StyledTableCell align="center" component="th" scope="row">
                  {row?.mobaile}
                </StyledTableCell>
                <StyledTableCell align="center" component="th" scope="row">
                  {row?.skill}
                </StyledTableCell>
                <StyledTableCell align="center">{row?.playercategurie?.points}</StyledTableCell>
                <StyledTableCell align="center">
                  <IconButton onClick={() => deletes(row.id)}>
                    <Delete color='error' />
                  </IconButton>
                  <IconButton onClick={() => {
                    dispatch(dispatch(dataCheng({ data: row, pk: 'player' })))
                    dispatch(dispatch(openModel({ st: 'u', key: 'playerMood' })))
                    dispatch(dispatch(openModel({ st: true, key: 'playerModel' })))
                  }} >
                    <Edit color='warning' />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default Player

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function CateguryModel() {
  const [loading, setloading] = useState(false);
  const { playerModel, player, playerMood } = useSelector(state => state.modalData)
  const { playerCategury } = useSelector(state => state.palyercategury)
  const dispatch = useDispatch()

  const [errors, setErrors] = useState({});

  const submit = () => {
    setloading(true);
    if (!validateForm(player, setErrors)) {
      setloading(false);
      return 0
    }

    const formData = new FormData();
    formData.append('player_no', player.player_no)
    formData.append('name', player.name)
    formData.append('mobaile', player.mobaile)
    formData.append('image', player.image)
    formData.append('skill', player.skill)
    formData.append('playercategurie_id', player.playercategurie_id)

    console.log(formData);
    axios.post(apiRoutes.player, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((e) => {
      console.log(e.data);
      dispatch(playerClear())
      dispatch(fetcchplayer())
      dispatch(openModel({ st: false, key: 'playerModel' }))
      toast.success(e.data.msg)
      setloading(false);
    }).catch(e => {
      console.log(e);
      setloading(false);
      toast.error(e.response.data.msg)
    })
  }
  const update = () => {
    setloading(true);
    if (!validateForm(player, setErrors)) {
      setloading(false);
      return 0
    }

    const formDatas = new FormData();
    formDatas.append('player_no', player.player_no)
    formDatas.append('name', player.name)
    formDatas.append('mobaile', player.mobaile)
    formDatas.append('image', player.image)
    formDatas.append('skill', player.skill)
    formDatas.append('playercategurie_id', player.playercategurie_id)

    axios.post(`${apiRoutes.player}update/${player.id}`, formDatas, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((e) => {
      console.log(e.data);
      dispatch(playerClear())
      dispatch(fetcchplayer())
      dispatch(openModel({ st: false, key: 'playerModel' }))
      toast.success(e.data.msg)
      setloading(false);
    }).catch(e => {
      console.log(e);
      setloading(false);
      toast.error(e.response.data.msg)
    })
  }

  const handleInputChange = (data) => {
    dispatch(onInputCheng(data))
  };

  const handleFileChange = (e, files) => {
    const file = files;
    if (file && file.size > 10240000) {
      setErrors({
        ...errors,
        logo: 'Card file size must be less than 10 MB',
      });
    } else {
      dispatch(onInputCheng(e));
    }
  };


  return (
    <>
      <Modal
        open={playerModel}
        onClose={() => {

          dispatch(playerClear())
          dispatch(openModel({ st: false, key: 'playerModel' }))
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="my-2">
            <center><h5>Create New Player</h5></center>
          </div>
          <div className="container">
            <div className="my-3">
              <TextField value={player.player_no} onChange={(e) => handleInputChange({ data: e.target.value, pk: 'player', ck: 'player_no' })} placeholder='Player No' fullWidth size='small' />
              <span style={{ color: 'red', marginTop: '10px' }}>{errors.player_no && errors.player_no}</span>
            </div>
            <div className="my-3">
              <TextField value={player.name} onChange={(e) => handleInputChange({ data: e.target.value, pk: 'player', ck: 'name' })} placeholder='Name' fullWidth size='small' />
              <span style={{ color: 'red', marginTop: '10px' }}>{errors.name && errors.name}</span>
            </div>
            <div className="my-3">
              <TextField value={player.mobaile} onChange={(e) => handleInputChange({ data: e.target.value, pk: 'player', ck: 'mobaile' })} placeholder='Mobile' fullWidth size='small' />
              <span style={{ color: 'red', marginTop: '10px' }}>{errors.mobaile && errors.mobaile}</span>
            </div>
            {/* <div className="my-3">
              <TextField value={player.skill} onChange={(e) => dispatch(onInputCheng({ data: e.target.value, pk: 'player', ck: 'points' }))} placeholder='Base Point' fullWidth size='small' />
            </div> */}
            <div className="my-3">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={player.playercategurie_id}
                fullWidth
                size='small'
                label="Player Categury"
                onChange={(e) => handleInputChange({ data: e.target.value, pk: 'player', ck: 'playercategurie_id' })} handleInputChange
              >
                <MenuItem value="Choose Categury"> Choose Categury </MenuItem>
                {playerCategury.map(data => <MenuItem key={data.id} value={data.id}>{data.name} {`(${data.points})`}</MenuItem>)}
              </Select>
              <span style={{ color: 'red', marginTop: '10px' }}>{errors.skill && errors.skill}</span>
            </div>

            <div className="my-3">
              <div className="my-3">
                <TextField value={player.skill} onChange={(e) => handleInputChange({ data: e.target.value, pk: 'player', ck: 'skill' })} placeholder='Skill' fullWidth size='small' />
              </div>
              <span style={{ color: 'red', marginTop: '10px' }}>{errors.playercategurie_id && errors.playercategurie_id}</span>
            </div>
            <div className="my-3">
              <TextField type='file' onChange={(e) => handleFileChange({ data: e.target.files[0], pk: 'player', ck: 'image' }, e.target.files[0])} fullWidth size='small' />
              <span style={{ color: 'red', marginTop: '10px' }}>{errors.logo && errors.logo}</span>
            </div>
            {playerMood == 'c' ?
              <>
                <div className="my-3">
                  <LoadingButton fullWidth size='small' loading={loading} onClick={() => submit()} variant='contained' >Create Player</LoadingButton>
                </div>
              </>
              :
              <>
                <div className="my-3">
                  <LoadingButton fullWidth size='small' variant='contained' onLoad={<>Hello</>} onClick={() => update()} color='warning' >Create Player</LoadingButton>
                </div>
              </>
            }
          </div>
        </Box>
      </Modal>
    </>
  )
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const validateForm = (formData, setErrors) => {
  let isValid = true;
  const newErrors = {};
  // Validate owner_name
  if (formData.player_no === '') {
    newErrors.player_no = 'Player No is required';
    isValid = false;
  }

  // Validate email
  if (formData.name.trim() === '') {
    newErrors.name = 'Name is required';
    isValid = false;
  }

  // Validate totale_points
  if (formData.mobaile === '') {
    newErrors.mobaile = 'Phone no. is required';
    isValid = false;
  }

  // Validate logo
  if (!formData.skill.trim() === '') {
    newErrors.skill = 'Skill is required';
    isValid = false;
  }
  if (!formData.playercategurie_id == "Choose Categury") {
    newErrors.playercategurie_id = 'Player Category is required';
    isValid = false;
  }

  setErrors(newErrors);
  return isValid;
};
